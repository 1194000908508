import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import CustomLinkButton from "./CustomLinkButton";

function Menu({ classes, onMenuClick }) {
  const history = useHistory();
  return (
    <div className={classes.links}>
      <CustomLinkButton
        to="/"
        color="inherit"
        onClick={() => onMenuClick(1)}
      >
        O que fazemos?
      </CustomLinkButton>
      <CustomLinkButton
        to="/"
        color="inherit"
        onClick={() => onMenuClick(4)}
      >
        Equipa
      </CustomLinkButton>
      {/* <CustomLinkButton
        to="/"
        color="inherit"
        onClick={() => onMenuClick(5)}
      >
        Galeria
      </CustomLinkButton> */}
      <CustomLinkButton
        to="/"
        color="inherit"
        onClick={() => onMenuClick(6)}
      >
        Contactos
      </CustomLinkButton>
      <Button variant="contained" color="primary" target="_blank" href="https://portal.solarpunk.pt">
        Area Cliente
      </Button>
    </div>
  );
}

export default Menu;
