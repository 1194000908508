
export const styles = theme => ({
  root: {
    zIndex: 2,
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 5,
    }
  },
  stemPlx: {
    opacity: 0,
    backgroundColor: "#b5b3b35e",
    padding: 20,
  },
  podsPlx: {
    opacity: 0,
    backgroundColor: "#b5b3b35e",
    padding: 20,
  },
  anim: {
    display: "block",
    margin: "0 auto",
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      width: 70,
    }
  },
  connection: {
    display: "block",
    margin: "0 auto",
    maxWidth: 80,
    transform: "rotate(22deg)",
  },
  inverted: {
    transform: "rotate(205deg)",
  },
  mobile: {
    transform: "rotate(-65deg)",
    maxWidth: 60,
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      maxWidth: 40,
    }
  },
  mobileInverted: {
    transform: "rotate(113deg)",
    maxWidth: 60,
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      maxWidth: 40,
    }
  },
  cardsContainer: {
    minHeight: 280,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column"
    },
  },
  mobileContainer: {
    height: "70vh",
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      height: "78vh",
    },
  }
});


export const stemAnim = ({viewHeight, screen}) => ([
  {
    start: viewHeight * (screen - 0.7),
    end: viewHeight * (screen - 0.5),
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
      {
        startValue: -200,
        endValue: 0,
        property: "translateX",
      },
    ],
  },
]);

export const podsAnim = ({viewHeight, screen}) => ([
  {
    start: viewHeight * (screen - 0.5),
    end: viewHeight * (screen - 0.2),
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
      {
        startValue: 200,
        endValue: 0,
        property: "translateX",
      },
    ],
  },
]);