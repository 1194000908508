import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Typography, Grid, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { styles } from "./Welcome.styles";

function Welcome({ classes, rClasses, onMore }) {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <video className={classes.videoBack} muted autoPlay loop playsInline >
        <source src="https://media-solarpunk.s3.eu-west-2.amazonaws.com/video.mp4" type="video/mp4" />
      </video>
      <div className={classes.videoOverlay}></div>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          className={classes.container}
          justify="flex-start"
          alignItems="flex-end"
        >
          {/* <Grid item xs={12} sm={3}></Grid> */}
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
          >
            <Typography variant="h1" component="h1" className={classes.title}>
              {t("home.Welcome.main.title")}
            </Typography>
            <div className={classes.spacer}></div>
            <Typography
              variant="h4"
              component="div"
              className={classes.description}
            >
              {t("home.Welcome.main.description")}
            </Typography>
            <div className={classes.spacer}></div>
            <div className={classes.spacer}></div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={onMore}
            >
              {t("home.Welcome.main.button")}
            </Button>
            <div className={classes.spacer}></div>
            <div className={classes.spacer}></div>
            <div className={classes.spacer}></div>
            <div className={classes.spacer}></div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(Welcome);
