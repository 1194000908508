export const styles = (theme) => ({
  root: {
    backgroundColor: "#55B849",
    color: "white",
    textAlign: "center",
    minHeight: 430,
    padding: 20,
    "& .MuiGrid-container": {
      minHeight: 390,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 230,
      padding: 5,
      "& .MuiGrid-container": {
        minHeight: 230,
      },
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: 190,
      padding: 5,
      "& .MuiGrid-container": {
        minHeight: 190,
      },
    },
  },
  img: {
    height: 140,
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      height: 90,
      margin: 10,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      height: 80,
      margin: 10,
    }
  },
  title: {
    textTransform: "uppercase",
    padding: 10,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      fontSize: 14,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 0,
      fontSize: 12,
    }
  },
  description: {
    padding: 10,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      fontSize: 11,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 0,
      fontSize: 10,
      lineHeight: "12px",
    },
  },
  btn: {
    width: "100%",
    fontWeight: "bold",
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 0,
      fontSize: 12,
    },
  },
});
