import React, { useState } from "react";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { scrollToScreen } from "../../../features/home/homeSlice";

import CustomLinkButton from "./CustomLinkButton";
import { styles } from "./Nav.styles";

import { useScrollPosition } from "../../../common/hooks/useScrollPosition";
import { useSpring, animated } from "react-spring";

import Menu from "./Menu";

function NavMobile({ classes }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const [stylesMobile, apiMobile] = useSpring(() => ({
    top: 0,
    config: { duration: 400 },
  }));

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 100 && currPos.y > prevPos.y) {
        apiMobile.start({ top: -100 });
      } else {
        apiMobile.start({ top: 0 });
      }
    },
    [],
    null,
    true,
    500
  );

  const onMenuClick = (screen) => {
    dispatch(scrollToScreen(screen));
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root}>
      <animated.div style={stylesMobile} className={classes.appbar}>
        <AppBar color="secondary" position="absolute">
          <Container maxWidth="lg">
            <Toolbar disableGutters className={classes.toolbar}>
              <IconButton
                color="inherit"
                edge="start"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <MenuIcon />
              </IconButton>
              <CustomLinkButton
                className={classes.logolinkMobile}
                to="/"
                color="inherit"
                onClick={() => dispatch(scrollToScreen(0))}
              >
                <img
                  src="/mainLogo.svg"
                  className={classes.logoMobile}
                  alt="logo"
                />
              </CustomLinkButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                className={classes.drawer}
                onClose={() => setDrawerOpen(false)}
              >
                <Menu classes={classes} onMenuClick={onMenuClick} />
              </Drawer>
            </Toolbar>
          </Container>
        </AppBar>
      </animated.div>
    </div>
  );
}

export default withStyles(styles)(NavMobile);
