export const styles = theme => ({
  root: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  videoBack: {
    top: "calc(-10vh + 40px)",
    left: "-15vw",
    width: "130vw",
    height: "120vh",
    zIndex: "0",
    position: "absolute",
    [theme.breakpoints.down('xs')]: {
      width: "280vw",
      left: "-90vw",
    },
  },
  videoOverlay: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#26343880",
    zIndex: "0",
    position: "absolute"
  },
  container: {
    height: "100vh",
    zIndex: "1",
  },
  title: {
    color: "white",
    zIndex: "1",
    [theme.breakpoints.down('xs')]: {
      fontSize: 70,
    },
  },
  description: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    zIndex: "1",
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  spacer: {
    height: 30,
    display: "block",
    width: "100%",
  },
  button: {
    fontSize: 24,
    zIndex: 2
  }
});