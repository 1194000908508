import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";

import { styles } from "./Contact.styles";
import { useTranslation } from "react-i18next";

function Contact({ classes, rClasses }) {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" className={rClasses.title}>
            {t("home.Contact.main.title")}
          </Typography>
          <Typography component="p" className={rClasses.subTitle}>
            {t("home.Contact.main.description")}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.textfieldContainer}>
          <div id="mc_embed_signup">
            <form
              action="https://solarpunk.us1.list-manage.com/subscribe/post?u=2d274774c6a767cc576abdfad&amp;id=0eec2ea17c"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
            >
              <div
                style={{ position: "absolute", left: -5000 }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_2d274774c6a767cc576abdfad_0eec2ea17c"
                  tabIndex="-1"
                />
              </div>
              <TextField
                variant="filled"
                className={classes.textfield}
                color="secondary"
                label="Email"
                name="EMAIL"
                id="mce-EMAIL"
                type="email"
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      id="mc-embedded-subscribe"
                      color="secondary"
                    >
                      ENVIAR
                    </Button>
                  ),
                }}
              />
            </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(Contact);
