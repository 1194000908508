export const styles = theme => ({
  c: {
    cursor: "pointer",
    willChange: "transform, opacity",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },
  
  container: {
    position: "relative",
    height: "100%",
  }
});




