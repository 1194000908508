import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/styles/withStyles";
import Plx from "react-plx";
import { Container, Grid, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

import useWindowDimensions from "../../../common/hooks/useWindowDimensions";

import automation from "./Solutions/automation.png";
import data from "./Solutions/data.png";
import monitoring from "./Solutions/monitoring.png";
import remote from "./Solutions/remote.png";

import {
  styles,
  item01Anim,
  item02Anim,
  item03Anim,
  item04Anim,
} from "./Solutions.styles";
import FeatureCard from "./Solutions/FeatureCard";

function Solutions({ classes, rClasses, containerRef }) {
  const { t } = useTranslation();
  const { height: viewHeight } = useWindowDimensions();
  const [offset, setOffset] = useState();

  useEffect(() => {
    if (containerRef?.current) {
      setOffset(containerRef?.current?.offsetTop);
    }
  }, [containerRef]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      {offset ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3" className={rClasses.title}>
              {t("home.Solutions.main.title")}
            </Typography>
            <Typography component="p" className={rClasses.subTitle}>
              {t("home.Solutions.main.description")}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={item01Anim({ viewHeight, offset })}
            >
              <FeatureCard
                img={monitoring}
                title={t("home.Solutions.monitoring.title")}
                text={t("home.Solutions.monitoring.description")}
                backtext={<Trans t={t}>{t("home.Solutions.monitoring.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={item02Anim({ viewHeight, offset })}
            >
              <FeatureCard
                img={automation}
                title={t("home.Solutions.automation.title")}
                text={t("home.Solutions.automation.description")}
                backtext={<Trans t={t}>{t("home.Solutions.automation.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={item03Anim({ viewHeight, offset })}
            >
              <FeatureCard
                img={remote}
                title={t("home.Solutions.remote.title")}
                text={t("home.Solutions.remote.description")}
                backtext={<Trans t={t}>{t("home.Solutions.remote.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={item04Anim({ viewHeight, offset })}
            >
              <FeatureCard
                img={data}
                title={t("home.Solutions.data.title")}
                text={t("home.Solutions.data.description")}
                backtext={<Trans t={t}>{t("home.Solutions.data.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" className={classes.footer}>
              {t("home.Solutions.main.footer")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default withStyles(styles)(Solutions);
