export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  photo: {
    margin: "0 auto",
    maxWidth: 270,
    display: "block",
    [theme.breakpoints.down('xs')]: {
      maxWidth: "80%",
    },
  },
  name: {
    color: "white",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 30,
    padding: 30,
    [theme.breakpoints.down('xs')]: {
      padding: "10px 0",
      fontSize: 16
    },
  },
  info: {
    color: "white",
    textAlign: "center",
    fontSize: 20,
    padding: "0 30px",
    whiteSpace: "pre-line",
    [theme.breakpoints.down('xs')]: {
      padding: "10px 0",
      fontSize: 12
    },
  }
});
