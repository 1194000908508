import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CustomThemeProvider from "./theme/CustomThemeProvider";
import "./i18n";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Hidden } from "@material-ui/core";

import MainLoader from "./components/MainLoader";

import Home from "../features/home/Home";

import Nav from "./components/public/Nav";
import NavMobile from "./components/public/NavMobile";

function App() {
  return (
    <CustomThemeProvider>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Container maxWidth={false} disableGutters={true}>
        <Suspense fallback={<MainLoader />}>
          <Router>
            <Switch>
              <Route path="/">
                <Hidden smUp>
                  <NavMobile />
                </Hidden>
                <Hidden xsDown>
                  <Nav />
                </Hidden>
                <Home />
              </Route>
            </Switch>
          </Router>
        </Suspense>
      </Container>
      </MuiPickersUtilsProvider>
    </CustomThemeProvider>
  );
}

export default App;
