import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { useSpring, a } from "@react-spring/web";
import { styles } from "./Flip.styles";

function Flip({ classes, front, back, className }) {
  const [flipped, set] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  return (
    <div className={classes.container + " " + className} onClick={() => back && set((state) => !state)}>
      <a.div
        className={classes.c}
        style={{ opacity: opacity.to((o) => 1 - o), transform }}
      >
        {front}
      </a.div>
      <a.div
        className={classes.c}
        style={{
          opacity,
          transform,
          rotateY: "180deg",
        }}
      >
        {back}
      </a.div>
    </div>
  );
}

export default withStyles(styles)(Flip);
