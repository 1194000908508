import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Paper, Typography, Grid } from "@material-ui/core";

import { styles } from "./ClientCard.styles";
import Flip from "../../../../common/components/Flip";

function ClientCard({ classes, img, title, text, backText }) {
  return (
    <Flip
      front={
        <Paper className={classes.root}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            {img && (
              <div
                className={classes.img}
                style={{ backgroundImage: "url('" + img + "')" }}
              ></div>
            )}
            <Grid container direction="column" item xs={12}>
              <Typography component="h3" variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      }
      back={
        <Paper className={classes.root}>
          <Grid
            className={classes.backContainer}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography component="p" className={classes.backDescription}>
                {backText}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      }
    />
  );
}

export default withStyles(styles)(ClientCard);
