import { createSlice } from "@reduxjs/toolkit";
import { guid, sleep } from "../common/utils";

export const coreSlice = createSlice({
  name: "core",
  initialState: {
    loader: null,
    snacks: []
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    addSnack: (state, action) => {
      state.snacks = [...state.snacks, action.payload];
      
    },

    removeSnack: (state, action) => {
      state.snacks = [...state.snacks.filter(item => item.id !== action.payload)];
    }
  },
});

export const {
  setLoader,
  addSnack,
  removeSnack,
} = coreSlice.actions;

export const addSnackAsync = (type, msg) => async (dispatch) => {
  let snack = {
    id: guid(),
    type,
    msg,
  }
  dispatch(addSnack(snack));
  await sleep(5000);
  dispatch(removeSnack(snack.id));
};

export const selectLoader = (state) => state.core.loader;
export const selectSnacks = (state) => state.core.snacks;

export default coreSlice.reducer;