
export const styles = theme => ({
  background: {
    backgroundImage: "url('background.jpg')",
    backgroundSize: "cover",
    width: "100%",
    height: "180vh",
    position: "fixed",
    zIndex: "-2",
    //filter: "blur(2px)"
  },
  screen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    position: "relative",
    minHeight: "800px",
    [theme.breakpoints.down('xs')]: {
      minHeight: "600px",
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: "500px",
    },
  },
  halfScreen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    width: "100%",
    position: "relative",
    minHeight: "400px",
    [theme.breakpoints.down('xs')]: {
      minHeight: "300px",
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: "250px",
    },
  },
  greenBack: {
    backgroundColor: "#55B849",
  },
  grayBack: {
    backgroundColor: "#263438",
  },
  scrollIcon: {
    position: "absolute",
    padding: "30px",
    bottom: "0px",
    height: 80,
    cursor: "pointer",
    zIndex: 3,
    // eslint-disable-next-line
    ['@media (max-width:400px)']: { 
      padding: 5,
      height: 30,
    },
  },
  shapesContainer: {
    position: "absolute",
    height: "600vh",
    width: "100%",
  },
  shapes: {
    position: "relative",
    height: "600vh",
    overflow: "hidden",
    width: "100%",
  },
  shape: {
    position: "absolute",
    zIndex: 1,
  },
  title: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    padding: 30,
    fontSize: 48,
    fontWeight: "bold",
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      padding: 0,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      fontSize: 24,
      padding: 0,
    },
  },
  subTitle: {
    color: "white",
    textAlign: "center",
    paddingBottom: 30,
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      paddingBottom: 5,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      fontSize: 13,
      lineHeight: "14px",
      paddingBottom: 5,
    },
  },
});


export const backAnim = ({viewHeight}) => ([
  {
    start: 0,
    end: viewHeight * 7,
    properties: [
      {
        startValue: 0,
        endValue: viewHeight * -1,
        property: "translateY",
      },
    ],
  },
]);