import React from "react";
import { Spring, animated } from "@react-spring/web";

function WirelessAnim({ className, delay, duration, img }) {
  return (
    <Spring
      loop
      delay={delay}
      from={{ opacity: 0.7 }}
      to={{ opacity: 1 }}
      config={{ duration }}
    >
      {(styles) => (
        <animated.div style={styles}>
          <img src={img} alt="anim" className={className} />
        </animated.div>
      )}
    </Spring>
  );
}

export default WirelessAnim;
