import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/styles/withStyles";
import Plx from "react-plx";
import { Container, Grid, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

import jardins from "./WhatWeDo/jardins.png";
import hidroponia from "./WhatWeDo/hidroponia.png";
import estufa from "./WhatWeDo/estufa.png";
import vinha from "./WhatWeDo/vinha.png";

import useWindowDimensions from "../../../common/hooks/useWindowDimensions";

import { styles, itemAnim } from "./WhatWeDo.styles";
import ClientCard from "./WhatWeDo/ClientCard";

function WhatWeDo({ classes, rClasses, containerRef }) {
  const { t } = useTranslation();
  const { height: viewHeight } = useWindowDimensions();
  const [offset, setOffset] = useState();

  useEffect(() => {
    if (containerRef?.current) {
      setOffset(containerRef?.current?.offsetTop);
    }
  }, [containerRef]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      {offset ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="h3" variant="h3" className={rClasses.title}>
              {t("home.WhatWeDo.main.title")}
            </Typography>
            {/* <Typography component="p" className={rClasses.subTitle}>
            {t("home.WhatWeDo.main.description")}
          </Typography> */}
          </Grid>
          <Grid item sm={3} xs={6}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={itemAnim({ viewHeight, offset })}
            >
              <ClientCard
                img={jardins}
                title={t("home.WhatWeDo.gardens.title")}
                text={t("home.WhatWeDo.gardens.description")}
                backText={<Trans t={t}>{t("home.WhatWeDo.gardens.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={itemAnim({ viewHeight, offset })}
            >
              <ClientCard
                img={hidroponia}
                title={t("home.WhatWeDo.hydroponics.title")}
                text={t("home.WhatWeDo.hydroponics.description")}
                backText={<Trans t={t}>{t("home.WhatWeDo.hydroponics.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={itemAnim({ viewHeight, offset })}
            >
              <ClientCard
                img={estufa}
                title={t("home.WhatWeDo.greenhouse.title")}
                text={t("home.WhatWeDo.greenhouse.description")}
                backText={<Trans t={t}>{t("home.WhatWeDo.greenhouse.flip")}</Trans>}
              />
            </Plx>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Plx
              className={classes.paperItemPlx}
              parallaxData={itemAnim({ viewHeight, offset })}
            >
              <ClientCard
                img={vinha}
                title={t("home.WhatWeDo.production.title")}
                text={t("home.WhatWeDo.production.description")}
                backText={<Trans t={t}>{t("home.WhatWeDo.production.flip")}</Trans>}
              />
            </Plx>
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export default withStyles(styles)(WhatWeDo);
