export const styles = theme => ({
  logolink: {
    padding: 0,
  },
  links: {
    marginBottom: 23,
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      flexDirection: "column"
    },
  },
  appbar: {
    [theme.breakpoints.down('xs')]: {
      zIndex: 1400,
      left: 0,
      right: 0,
      position: "fixed",
      top: 0,
    },
  },
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      height: 90,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      height: 70,
    }
  },
  logolinkMobile: {
    position: "absolute",
    left: 50,
    right: 50,
    top: 0,
    padding: 0,
  },
  logoMobile: {
    height: 70,
    margin: 10,
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      height: 50,
    }
  },
  drawer: {
    "& .MuiDrawer-paper": {
      width: "60vw",
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      overflow: "visible",
      padding: 20,
      paddingTop: 110,
    },
    "& a": {
      margin: "5px 0",
    }
  }
});

export const logoAnim = () => ([
  {
    start: 0,
    end: 500,
    properties: [
      {
        startValue: 1,
        endValue: 0.4,
        property: "scale",
      },
    ],
  },
]);