import React from "react";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { AppBar, Container, Toolbar, Grid } from "@material-ui/core";

import { scrollToScreen } from "../../../features/home/homeSlice";

import CustomLinkButton from "./CustomLinkButton";
import { styles } from "./Nav.styles";

import { useScrollPosition } from "../../../common/hooks/useScrollPosition";
import { useSpring, animated } from "react-spring";

import Menu from "./Menu";

function Nav({ classes }) {
  const dispatch = useDispatch();

  const [styles, api] = useSpring(() => ({
    height: 120,
    margin: "30px 0",
    config: { duration: 400 },
  }));

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y > 100) {
        api.start({ height: 60, margin: "10px 0" });
      } else {
        api.start({ height: 120, margin: "30px 0" });
      }
    },
    [],
    null,
    true,
    300
  );

  const onMenuClick = (screen) => {
    dispatch(scrollToScreen(screen));
  };

  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="fixed" className={classes.appbar}>
        <Container maxWidth="lg">
          <Toolbar disableGutters className={classes.toolbar}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
            >
              <CustomLinkButton
                className={classes.logolink}
                to="/"
                color="inherit"
                onClick={() => dispatch(scrollToScreen(0))}
              >
                <animated.div style={styles}>
                  <img
                    src="/mainLogo.svg"
                    className={classes.logo}
                    style={{
                      margin: 0,
                      height: "100%",
                    }}
                    alt="logo"
                  />
                </animated.div>
              </CustomLinkButton>
              <Menu classes={classes} onMenuClick={onMenuClick} />
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(Nav);
