import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography } from "@material-ui/core";

import { styles } from "./HowWeDoCard.styles";
import Flip from "../../../../common/components/Flip";
import StretchCard from "../../../../common/components/StretchCard";

function HowWeDoCard({ classes, title, text, backtext }) {
  return (
    <Flip
      className={classes.flip}
      front={
        <StretchCard className={classes.card}>
            <Typography
              component="h3"
              variant="h3"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              component="p"
              className={classes.description}
            >
              {text}
            </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            SABER MAIS
          </Button> */}
        </StretchCard>
      }
      // back={
      //   <Paper className={classes.root}>
      //     <Grid container justify="center" alignItems="center">
      //       <Typography component="p" className={classes.description}>
      //         {backtext}
      //       </Typography>
      //     </Grid>
      //   </Paper>
      // }
    />
  );
}

export default withStyles(styles)(HowWeDoCard);
