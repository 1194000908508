import React from "react";
import { Link } from 'react-router-dom';
import {
  Button
} from "@material-ui/core";

export default function CustomLinkButton({ to, children, ...rest }) {
  const CustomLinkAux = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  return (
    <Button {...rest} component={CustomLinkAux}>
      {children}
    </Button>
  );
}