export const styles = theme => ({
  root: {
    backgroundColor: "#263438",
    color: "white",
    textAlign: "center",
    minHeight: 480,
    [theme.breakpoints.down('xs')]: {
      minHeight: 230,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: 230,
    },
  },
  backContainer: {
    minHeight: 480,
    [theme.breakpoints.down('xs')]: {
      minHeight: 230,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: 230,
    },
  },
  img: {
    height: 300,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down('xs')]: {
      height: 110,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      height: 70,
    },
  },
  title: {
    textTransform: "uppercase",
    padding: 10,
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 5,
      fontSize: 20,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 5,
      fontSize: 14,
    },
  },
  description: {
    padding: 10,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 5,
      fontSize: 12,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 2,
      fontSize: 16,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: 2,
      fontSize: 11,
    },
  },
  backDescription: {
    padding: 10,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      padding: "0 15px",
      fontSize: 12,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      padding: "0 5px",
      fontSize: 11,
    },
  },
});