import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { useTranslation, Trans } from "react-i18next";

import { Container, Grid, Typography, Hidden } from "@material-ui/core";

// import useWindowDimensions from "../../../common/hooks/useWindowDimensions";

import { styles } from "./HowWeDo.styles";

import cerebro from "./HowWeDo/cerebro.gif";
import pods from "./HowWeDo/pods.gif";
import PC from "./HowWeDo/PC.png";
import wireless from "./HowWeDo/wireless.png";

import HowWeDoCard from "./HowWeDo/HowWeDoCard";
import WirelessAnim from "./HowWeDo/WirelessAnim";

function HowWeDo({ classes, rClasses, screen }) {
  const { t } = useTranslation();
  // const { height: viewHeight } = useWindowDimensions();
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" className={rClasses.title}>
            {t("home.HowWeDo.main.title")}
          </Typography>
          <Typography component="p" className={rClasses.subTitle}>
            {t("home.HowWeDo.main.description")}
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={3}>
                <img src={PC} alt="anim" className={classes.anim} />
              </Grid>
              <Grid item xs={1}>
                <WirelessAnim
                  className={classes.connection}
                  delay={100}
                  duration={300}
                  img={wireless}
                />
                <WirelessAnim
                  className={classes.connection + " " + classes.inverted}
                  delay={50}
                  duration={300}
                  img={wireless}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={cerebro} alt="anim" className={classes.anim} />
              </Grid>
              <Grid item xs={1}>
                <WirelessAnim
                  className={classes.connection}
                  delay={0}
                  duration={350}
                  img={wireless}
                />
                <WirelessAnim
                  className={classes.connection + " " + classes.inverted}
                  delay={100}
                  duration={300}
                  img={wireless}
                />
              </Grid>
              <Grid item xs={3}>
                <img src={pods} alt="anim" className={classes.anim} />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden xsDown>
        <Grid container style={{}} className={classes.cardsContainer}>
          <Grid item xs={4}>
            <HowWeDoCard
              title={t("home.HowWeDo.portal.title")}
              text={<Trans t={t}>{t("home.HowWeDo.portal.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.portal.flip")}</Trans>}
            />
          </Grid>
          <Grid item xs={4}>
            <HowWeDoCard
              title={t("home.HowWeDo.stem.title")}
              text={<Trans t={t}>{t("home.HowWeDo.stem.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.stem.flip")}</Trans>}
            />
          </Grid>
          <Grid item xs={4}>
            <HowWeDoCard
              title={t("home.HowWeDo.pods.title")}
              text={<Trans t={t}>{t("home.HowWeDo.pods.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.pods.flip")}</Trans>}
            />
          </Grid>
        </Grid>
      </Hidden>
      {/* MOBILE */}
      <Hidden smUp>
        <Grid container className={classes.mobileContainer}>
          <Grid
            item
            xs={5}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <img src={PC} alt="anim" className={classes.anim} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <WirelessAnim
                className={classes.connection + " " + classes.mobile}
                delay={100}
                duration={300}
                img={wireless}
              />
              <WirelessAnim
                className={classes.connection + " " + classes.mobileInverted}
                delay={50}
                duration={300}
                img={wireless}
              />
            </div>
            <img src={cerebro} alt="anim" className={classes.anim} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <WirelessAnim
                className={classes.connection + " " + classes.mobile}
                delay={100}
                duration={300}
                img={wireless}
              />
              <WirelessAnim
                className={classes.connection + " " + classes.mobileInverted}
                delay={50}
                duration={300}
                img={wireless}
              />
            </div>
            <img src={pods} alt="anim" className={classes.anim} />
            <div>&nbsp;</div>
          </Grid>
          <Grid
            item
            xs={7}
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <HowWeDoCard
              style={{ height: "calc(100% - 10px)", padding: 5 }}
              title={t("home.HowWeDo.portal.title")}
              text={<Trans t={t}>{t("home.HowWeDo.portal.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.portal.flip")}</Trans>}
            />

            <HowWeDoCard
              style={{ height: "calc(100% - 10px)", padding: 5 }}
              title={t("home.HowWeDo.stem.title")}
              text={<Trans t={t}>{t("home.HowWeDo.stem.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.stem.flip")}</Trans>}
            />

            <HowWeDoCard
              style={{ height: "calc(100% - 10px)", padding: 5 }}
              title={t("home.HowWeDo.pods.title")}
              text={<Trans t={t}>{t("home.HowWeDo.pods.description")}</Trans>}
              backtext={<Trans t={t}>{t("home.HowWeDo.pods.flip")}</Trans>}
            />
          </Grid>
        </Grid>
      </Hidden>
    </Container>
  );
}

export default withStyles(styles)(HowWeDo);
