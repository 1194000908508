import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import themeJson from "./defaultTheme";

export default function CustomThemeProvider({ children, darkMode }) {
  themeJson.palette.type = darkMode ? "dark" : "light";
  const theme = createMuiTheme(themeJson);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
