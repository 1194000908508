import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { Hidden } from "@material-ui/core";

import { styles } from "./Home.styles";
import Welcome from "./components/Welcome";

import { selectScrollTo, selectActiveScroll, scrollDone } from "./homeSlice";

import { animateScroll } from "react-scroll";
import HowWeDo from "./components/HowWeDo";
import Solutions from "./components/Solutions";
import WhatWeDo from "./components/WhatWeDo";
import Team from "./components/Team";
// import Instagram from "./components/Instagram";
// import Feedback from "./components/Feedback";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import scrollGrey from "./components/common/scrollGrey.svg";
import scrollGreen from "./components/common/scrollGreen.svg";
import shape1 from "./components/common/shape1.png";
import shape2 from "./components/common/shape2.png";
import shape3 from "./components/common/shape3.png";

function Home({ classes }) {
  const dispatch = useDispatch();
  const scrollTo = useSelector(selectScrollTo);
  const activeScroll = useSelector(selectActiveScroll);

  const whatwedoRef = useRef();
  const solutionsRef = useRef();
  const howwedoRef = useRef();
  const teamRef = useRef();
  const instagramRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    if (activeScroll) {
      scroll(scrollTo);
      dispatch(scrollDone());
    }
    console.log("scrollTo: %o", scrollTo);
    // eslint-disable-next-line
  }, [scrollTo]);

  const scroll = (screenId) => {
    switch (screenId) {
      case 1:
        animateScroll.scrollTo(whatwedoRef?.current?.offsetTop);
        break;
      case 2:
        animateScroll.scrollTo(solutionsRef?.current?.offsetTop);
        break;
      case 3:
        animateScroll.scrollTo(howwedoRef?.current?.offsetTop);
        break;
      case 4:
        animateScroll.scrollTo(teamRef?.current?.offsetTop);
        break;
      case 5:
        animateScroll.scrollTo(instagramRef?.current?.offsetTop);
        break;
      case 6:
        animateScroll.scrollTo(contactRef?.current?.offsetTop);
        break;
      default:
        animateScroll.scrollTo(0);
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.shapesContainer}>
        <Hidden xsDown>
          <div className={classes.shapes}>
            <img
              src={shape2}
              alt="shape"
              className={classes.shape}
              style={{
                top: "66vh",
                left: "-38vw",
                transform: "rotate(219deg) scale(0.9)",
                opacity: "0.3",
              }}
            />
            <img
              src={shape3}
              alt="shape"
              className={classes.shape}
              style={{
                top: "179vh",
                left: "-40vw",
                transform: "rotate(-329deg) scale(0.9)",
                opacity: "0.3",
              }}
            />
            <img
              src={shape3}
              alt="shape"
              className={classes.shape}
              style={{
                top: "249vh",
                right: "-40vw",
                transform: "rotate(310deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape3}
              alt="shape"
              className={classes.shape}
              style={{
                top: "368vh",
                right: "-30vw",
                transform: "rotate(247deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape3}
              alt="shape"
              className={classes.shape}
              style={{
                top: "437vh",
                left: "-30vw",
                transform: "rotate(-68deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape2}
              alt="shape"
              className={classes.shape}
              style={{
                top: "563vh",
                right: "-30vw",
                transform: "rotate(-3deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape3}
              alt="shape"
              className={classes.shape}
              style={{
                top: "563vh",
                left: "-30vw",
                transform: "rotate(-3deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape1}
              alt="shape"
              className={classes.shape}
              style={{
                top: "640vh",
                right: "-40vw",
                transform: "rotate(289deg) scale(0.9)",
                opacity: "0.3",
              }}
            />

            <img
              src={shape1}
              alt="shape"
              className={classes.shape}
              style={{
                top: "600vh",
                left: "-40vw",
                transform: "scale(0.9)",
                opacity: "0.3",
              }}
            />
          </div>
        </Hidden>
      </div>
      {/* <Plx
        className={classes.background}
        parallaxData={backAnim({ viewHeight })}
      ></Plx> */}

      <div className={classes.screen + " " + classes.grayBack}>
        <Welcome onMore={() => scroll(1)} rClasses={classes} />
      </div>
      <div
        className={classes.screen + " " + classes.greenBack}
        ref={whatwedoRef}
      >
        <WhatWeDo
          screen={1}
          rClasses={classes}
          containerRef={whatwedoRef}
        />
        <img
          src={scrollGrey}
          alt="scroll"
          className={classes.scrollIcon}
          onClick={() => scroll(2)}
        />
      </div>
      <div
        className={classes.screen + " " + classes.grayBack}
        ref={solutionsRef}
      >
        <Solutions screen={2} rClasses={classes} containerRef={solutionsRef}/>
        <img
          src={scrollGreen}
          alt="scroll"
          className={classes.scrollIcon}
          onClick={() => scroll(3)}
        />
      </div>
      <div
        className={classes.screen + " " + classes.greenBack}
        ref={howwedoRef}
      >
        <HowWeDo screen={3} rClasses={classes} />
        <img
          src={scrollGrey}
          alt="scroll"
          className={classes.scrollIcon}
          onClick={() => scroll(4)}
        />
      </div>
      <div className={classes.screen + " " + classes.grayBack} ref={teamRef}>
        <Team rClasses={classes} />
        <img
          src={scrollGreen}
          alt="scroll"
          className={classes.scrollIcon}
          onClick={() => scroll(6)}
        />
      </div>
      {/* <div
        className={classes.halfScreen + " " + classes.greenBack}
        ref={instagramRef}
      >
        <Instagram rClasses={classes} />
      </div>
      <div className={classes.halfScreen + " " + classes.grayBack}>
        <Feedback rClasses={classes} />
        <img
          src={scrollGreen}
          alt="scroll"
          className={classes.scrollIcon}
          onClick={() => scroll(6)}
        />
      </div> */}
      <div
        className={classes.halfScreen + " " + classes.greenBack}
        ref={contactRef}
      >
        <Contact rClasses={classes} />
      </div>
      <div className={classes.halfScreen + " " + classes.grayBack}>
        <Footer />
      </div>
    </div>
  );
}

export default withStyles(styles)(Home);
