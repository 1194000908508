import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    scrollTo: null,
    activeScroll: false,
  },
  reducers: {
    setScrollTo: (state, action) => {
      state.scrollTo = action.payload;
    },
    setActiveScroll: (state, action) => {
      state.activeScroll = action.payload;
    },
  },
});

export const { setScrollTo, setActiveScroll } = homeSlice.actions;

export const scrollToScreen = (screenID) => (dispatch) => {
  dispatch(setScrollTo(screenID));
  dispatch(setActiveScroll(true));
};

export const scrollDone = () => (dispatch) => {
  dispatch(setScrollTo(null));
  dispatch(setActiveScroll(false));
};

export const selectScrollTo = (state) => state.home.scrollTo;
export const selectActiveScroll = (state) => state.home.activeScroll;

export default homeSlice.reducer;
