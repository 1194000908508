import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./FeatureCard.styles";
import Flip from "../../../../common/components/Flip";

function FeatureCard({ classes, img, title, text, backtext }) {
  return (
    <Flip
      front={
        <Paper className={classes.root}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <img src={img} alt="icon" className={classes.img} />
              <Typography component="h3" variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {text}
              </Typography>
            </Grid>
            <Button variant="contained" color="secondary" className={classes.btn}>
              SABER MAIS
            </Button>
          </Grid>
        </Paper>
      }
      back={
        <Paper className={classes.root}>
          <Grid
            container
            justify="center"
            alignItems="center"
          >
               <Typography component="p" className={classes.description}>
                {backtext}
              </Typography>
          </Grid>
        </Paper>
      }
    />
  );
}

export default withStyles(styles)(FeatureCard);
