export const styles = (theme) => ({
  root: {
    backgroundColor: "#263438",
    color: "white",
    textAlign: "center",
    minHeight: 280,
    "& .MuiGrid-container": {
      minHeight: 280,
    },
    width: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minHeight: "calc(100% - 10px)",
      padding: 5,
      "& .MuiGrid-container": {
        minHeight: "100%",
      },
    },
  },

  card: {
    backgroundColor: "#263438",
    color: "white",
    textAlign: "center",
    height: 280,
    padding: 20,
    "& .MuiGrid-container": {
      height: 280,
    },
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5,
      height: "calc(100% - 10px)",
      width: "100%",
    },
  },
  flip: {
    margin: "0 auto",
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    textTransform: "uppercase",
    padding: "0 10px",
    fontSize: 24,
    color: "#55B849",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5,
      fontSize: 18,
    },
    // eslint-disable-next-line
    ["@media (max-width:400px)"]: {
      padding: 0,
      fontSize: 14,
    },
  },
  description: {
    padding: 10,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 5,
      fontSize: 10,
    },
    // eslint-disable-next-line
    ["@media (max-width:400px)"]: {
      padding: 0,
      lineHeight: "12px",
    },
  },
  btn: {
    width: "100%",
    fontWeight: "bold",
    // eslint-disable-next-line
    ["@media (max-width:400px)"]: {
      padding: 0,
      fontSize: 12,
    },
  },
});
