import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";

import { styles } from "./TeamMember.styles";

function TeamMember({ classes, img, name, lastName, info }) {
  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={5}>
        <img src={img} alt={name} className={classes.photo} />
      </Grid>
      <Grid item sm={12} xs={7}>
        <Typography component="h6" className={classes.name}>
          {name}
          <br />
          {lastName}
        </Typography>
        <Typography component="h6" className={classes.info}>
          {info}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(TeamMember);
