export const styles = (theme) => ({
  root: {
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      padding: 2,
    },
    "& .MuiGrid-spacing-xs-3": {
      width: "calc(100% + 4px)",
      margin: "-2px",
      "& > .MuiGrid-item": {
        [theme.breakpoints.down('xs')]: {
          padding: 2,
        },
      }
    }
  },
  paperItemPlx: {
    transform: "scale(0.6)",
    opacity: 0,
    minHeight: 440,
    [theme.breakpoints.down('xs')]: {
      minHeight: 230,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: 230,
    },
  },
});

export const itemAnim = ({ viewHeight, offset }) => {
  // console.log('viewHeight: %o, offset: %o', viewHeight, offset);
  // console.log('start: %o', offset - (viewHeight * 0.6));
  return [
    {
      // start: viewHeight * (screen - 0.4),
      // end: viewHeight * (screen - 0.05),
      start: (offset - viewHeight) + (viewHeight * 0.6),
      end: (offset - viewHeight) + (viewHeight * 0.9),
      properties: [
        {
          startValue: 0.6,
          endValue: 1,
          property: "scale",
        },
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ];
};
