export const styles = (theme) => ({
  root: {
    zIndex: 2,
  },
  paperItemPlx: {
    transform: "translateY(490px)",
    minHeight: 490,
    [theme.breakpoints.down("xs")]: {
      minHeight: 235,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      minHeight: 195,
    },
  },
  footer: {
    color: "white",
    textAlign: "center",
    paddingTop: 30,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 5,
      fontSize: 14,
    },
    // eslint-disable-next-line
    ['@media (max-width:400px)']: {
      fontSize: 11,
      lineHeight: "12px",
    },
  },
});

const threshold = 0.10;
const start = 0.4;
function getStart(offset, viewHeight, pos) {
  let multiplier = start + pos * threshold;
  // console.log('start[%s]: %o', pos, offset - viewHeight + viewHeight * multiplier);
  return offset - viewHeight + viewHeight * multiplier;
}
function getEnd(offset, viewHeight, pos) {
  let multiplier = start + (pos + 1) * threshold;
  // console.log('end[%s]: %o', pos, offset - viewHeight + viewHeight * multiplier);
  return offset - viewHeight + viewHeight * multiplier;
}

export const item01Anim = ({ viewHeight, offset }) => [
  {
    // start: viewHeight * 1.4,
    // end: viewHeight * 1.55,
    start: getStart(offset, viewHeight, 0),
    end: getEnd(offset, viewHeight, 0),
    properties: [
      {
        startValue: 420,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];

export const item02Anim = ({ viewHeight, offset }) => [
  {
    start: getStart(offset, viewHeight, 1),
    end: getEnd(offset, viewHeight, 1),
    properties: [
      {
        startValue: 420,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];

export const item03Anim = ({ viewHeight, offset }) => [
  {
    start: getStart(offset, viewHeight, 2),
    end: getEnd(offset, viewHeight, 2),
    properties: [
      {
        startValue: 420,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];

export const item04Anim = ({ viewHeight, offset }) => [
  {
    start: getStart(offset, viewHeight, 3),
    end: getEnd(offset, viewHeight, 3),
    properties: [
      {
        startValue: 420,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];
