const theme = {
  palette: {
    primary: {
      main: "#55B849",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#263438",
    },
    background: {
      default: "#f1f2f2",
    },
  },
  vars: {
    drawerWidth: 180,
    toolbarHeight: 60,
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiListItemText: {
      root: {
        "& span": {
          fontSize: 14,
        },
      },
    },
    MuiPopover: {
      root: {
        zIndex: "1400 !important",
      },
    },
    body: {
      backgroundColor: "red",
    },
  },
};

export default theme;
