import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Container, Grid, Typography, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Alexandre from "./Team/Alex.png";
import Manuel from "./Team/Manuel.png";
import Simao from "./Team/Simao.png";

import { styles } from "./Team.styles";
import TeamMember from "./Team/TeamMember";

function Team({ classes, rClasses }) {
  const { t } = useTranslation();
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" className={rClasses.title}>
            {t("home.Team.main.title")}
          </Typography>
          <Typography component="p" className={rClasses.subTitle}>
            {t("home.Team.main.description")}
          </Typography>
        </Grid>
      </Grid>
      <Hidden smUp><Grid container spacing={3}>
          <Grid item xs={12}>
            <TeamMember
              img={Alexandre}
              name={t("home.Team.alexandre.name")}
              lastName={t("home.Team.alexandre.lastName")}
              info={t("home.Team.alexandre.info")}
            />
          </Grid>
          <Grid item xs={12}>
            <TeamMember
              img={Manuel}
              name={t("home.Team.manuel.name")}
              lastName={t("home.Team.manuel.lastName")}
              info={t("home.Team.manuel.info")}
            />
          </Grid>
          <Grid item xs={12}>
            <TeamMember
              img={Simao}
              name={t("home.Team.simao.name")}
              lastName={t("home.Team.simao.lastName")}
              info={t("home.Team.simao.info")}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TeamMember
              img={Alexandre}
              name={t("home.Team.alexandre.name")}
              lastName={t("home.Team.alexandre.lastName")}
              info={t("home.Team.alexandre.info")}
            />
          </Grid>
          <Grid item xs={4}>
            <TeamMember
              img={Manuel}
              name={t("home.Team.manuel.name")}
              lastName={t("home.Team.manuel.lastName")}
              info={t("home.Team.manuel.info")}
            />
          </Grid>
          <Grid item xs={4}>
            <TeamMember
              img={Simao}
              name={t("home.Team.simao.name")}
              lastName={t("home.Team.simao.lastName")}
              info={t("home.Team.simao.info")}
            />
          </Grid>
        </Grid>
      </Hidden>
    </Container>
  );
}

export default withStyles(styles)(Team);
