export const styles = theme => ({
  root: {
    zIndex: 2,
  },
  title: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    padding: 30,
    fontSize: 48
  },
  description: {
    color: "white",
    textAlign: "center",
    paddingBottom: 30,
    fontSize: 24,
  },
});