export const styles = (theme) => ({
  root: {
    zIndex: 2,
  },
  logo: {
    maxWidth: "70%",
  },
  title: {
    color: "#55B849",
    fontWeight: "bold",
    fontSize: 24,
  },
  contact: {
    color: "white",
    fontSize: 18,
    '@media (max-width:400px)': {
      fontSize: 14,
    }
  },
  brand: {
    marginTop: 80,
    color: "#999",
    fontSize: 12,
    '@media (max-width:400px)': {
      marginTop: 10,
      fontSize: 10,
    }
  }
});
