import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Paper } from "@material-ui/core";

import { styles } from "./StretchCard.styles";

function StretchCard({ classes, className, children, style }) {
  return (
    <Paper className={className + " " + classes.root} style={style}>
      {children}
    </Paper>
  );
}

export default withStyles(styles)(StretchCard);