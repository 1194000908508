export const styles = theme => ({
  root: {
    zIndex: 2
  },
  textfieldContainer: {
    textAlign: "center",
  },
  textfield: {
    minWidth: "50%",
    backgroundColor: "white",
  }
});