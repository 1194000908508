import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Container, Typography, Grid, Hidden } from "@material-ui/core";

import { styles } from "./Footer.styles";

import logo from "./Footer/logo.svg";

function Footer({ classes }) {
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item xs={6} sm={4}>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography component="p" className={classes.brand}>
            Solarpunk é uma marca registada com autorização de uso da empresa Organizações Fesil, LDA (NIPC: 501 663 479)
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={4}></Grid>
        </Hidden>
        <Grid item xs={6} sm={4}>
          <Typography component="h6" variant="h6" className={classes.title}>
            CONTACTO
          </Typography>
          <Typography component="p" className={classes.contact}>
            info@solarpunk.pro
          </Typography>
          <br />
          <Typography component="p" className={classes.contact}>
            Telemóvel: 966597551
          </Typography>
          <br />
          <Typography component="p" className={classes.contact}>
            Rua dos Três Lagares,<br />
            Incubadora A Praça<br />
            6230 - 421 Fundão<br />
            Portugal
          </Typography>

        </Grid>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(Footer);
