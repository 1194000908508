import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export default function MainLoader() {
  return (
    <Grid container style={{ height: "100vh" }} justify="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
}
